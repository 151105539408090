import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        width: "100%",
      }}
      className=""
    >
      <h1>Not Found</h1>
    </div>
  );
};

export default NotFound;
